<template>
  <div class="container">
    <div class="card">
      <div class="d-flex justify-content-around mt-5 flex-wrap">
        <div>
          <!-- <select
                class="sel"
        v-model="selected_gender"
        clearable
        placeholder="Select"
        @change="setGender(selected_gender)"
      >
        <option
        class="sel"
          v-for="option in this.gender_list"
          :value="option.value"
          :key="option.value"
        >
          {{ option.type }}</option
        >
      </select> -->
          <p class="text-right">
            <i class="fa fa-user"></i>
            النوع :
          </p>
          <el-select
            class="sel text-right"
            v-model="selected_gender"
            clearable
            placeholder="اختر"
            @change="setGender(selected_gender)"
          >
            <el-option
              class="select_boxes_items"
              v-for="option in this.gender_list"
              :value="option.value"
              :key="option.value"
              :label="option.type"
            />
          </el-select>
        </div>
        <div>
          <p class="text-right"><i class="fa fa-globe"></i> البلد:</p>
          <el-select
            class="sel"
            v-model="selected_country"
            clearable
            placeholder="اختر"
            @change="setCountry(selected_country)"
          >
            <el-option
              class="select_boxes_items"
              v-for="option in this.country_list"
              :value="option.value"
              :key="option.value"
              :label="option.type"
            />
          </el-select>
        </div>
        <div>
          <p class="text-right">
            <i class="fa fa-child"></i>
            الفئة العمرية:
          </p>
          <el-select
            class="sel"
            v-model="selected_age_group"
            clearable
            placeholder="اختر"
            @change="setAgeGroup(selected_age_group)"
          >
            <el-option
              class="select_boxes_items"
              v-for="option in this.age_group"
              :value="option.value"
              :key="option.value"
              :label="option.type"
            />
          </el-select>
          <button class="btn btn-primary mr-2" @click="filter">Filter</button>
        </div>
      </div>

      <h5 class="pt-5 text-center">
        <i class="fa fa-arrow-circle-down" aria-hidden="true"></i> اختر سؤال
      </h5>
      <div class="d-flex justify-content-center pt-2">
        <!-- <select v-model="question" @change="setQuestion(question)" class="sel">
          <option
            v-for="option in this.question_img"
            :value="option.question"
            :key="option.question"
            >{{ option.question }}</option
          >
        </select> -->

        <el-select
          class="sel"
          v-model="question"
          clearable
          placeholder="اختر"
          @change="setQuestion(question)"
        >
          <el-option
            class="select_boxes_items"
            v-for="option in this.question_img"
            :value="option.question"
            :key="option.question"
            :label="option.type"
          />
        </el-select>
      </div>
      <div class="image text-center mt-3">
        <img
          :src="
            require('../../../public/static/img/graph_' + img_index + '.jpg')
          "
          alt=""
        />
      </div>

      <div id="" class="mb-5 mt-5">
        <apexchart
          v-if="state"
          type="bar"
          height="750"
          :options="barChart.chartOptions"
          :series="barChart.series"
        ></apexchart>
      </div>
    </div>

    <div class="card py-5">
      <div class="d-flex justify-content-around flex-wrap py-5">
        <div id="">
          <h4 class="text-center text-secondary">النوع:</h4>
          <apexchart
            type="pie"
            :options="GenderPieChart.chartOptions"
            :series="GenderPieChart.series"
          ></apexchart>
        </div>
        <div id="chart">
          <h4 class="text-center text-secondary">العمر:</h4>
          <apexchart
            type="pie"
            :options="AgePieChart.chartOptions"
            :series="AgePieChart.series"
          ></apexchart>
        </div>

        <div id="chart" class="">
          <h4 class="text-center text-secondary">البلد:</h4>
          <apexchart
            type="pie"
            width="330"
            :options="CountryPieChart.chartOptions"
            :series="CountryPieChart.series"
          ></apexchart>
        </div>
      </div>
    </div>

    <div class="card text-right py-5 px-5 d-flex flex-wrap">
      <h3><i class="fa fa-leanpub"></i> اجابات "سؤال ما اسم القصة"</h3>
      <div v-for="label in this.label3" :key="label">
        <p>
          <i class="fa fa-long-arrow-left" aria-hidden="true"></i>{{ label }}
        </p>
        <hr />
      </div>
    </div>
  </div>
</template>
<script>
import { ChartCard } from "src/components/index";
import circleChart from "../../components/base/circle-chart.vue";
import ApexCharts from "apexcharts";
import VueApexCharts from "vue-apexcharts";
import users from "../../pages/Dashboard/Tables/users";
export default {
  name: "score",
  components: {
    ChartCard,
    circleChart,
    VueApexCharts,
    ApexCharts,
  },
  data() {
    return {
      state: true,
      selected_filters: {},
      gender_list: [
        { type: "ذكر", value: 0 },
        { type: "انثى", value: 1 },
        { type: "الكل", value: 2 },
      ],
      country_list: [
        { type: "مصر", value: "مصر" },
        { type: "السعودية", value: "السعودية" },
        { type: "العراق", value: "العراق" },
        { type: "الاردن", value: "الاردن" },
        { type: "فلسطين", value: "فلسطين" },
        { type: "الجزائر", value: "الجزائر" },
        { type: "سوريا", value: "سوريا" },
        { type: "الامارات العربية", value: "الامارات العربية" },
        { type: "ألمانيا", value: "ألمانيا" },
        { type: "فرنسا", value: "فرنسا" },
        { type: "الولايات التحدة", value: "الولايات المتحدة" },
        { type: "انجترا", value: "انجلترا" },
        { type: "الكل", value: "الكل" },
      ],
      age_group: [
        { value: 1, type: this.$t("less_than_6") },
        { value: 2, type: this.$t("from_6_to_8") },
        { value: 3, type: this.$t("from_9_to_12") },
        { value: 4, type: this.$t("more_than_13") },
        { value: 5, type: this.$t("all_ages") },
      ],

      selected_gender: null,
      selected_country: null,
      selected_age_group: null,
      answersCount: [],
      answers: [],
      ages: [],
      img_index: null,
      question_img: [],
      ages_count: [],
      countries: [],
      countries_count: [],
      genders: [],
      label: [],
      count: [],
      label2: [],
      count2: [],
      z: [],
      label3: [],
      count3: [],
      users: null,
      selected: null,
      question: null,
      questions: [],
      first_question: [],
      id: this.$route.params.id,
      score: [],
      score2: [],
      males: null,
      label3: [],
      females: null,
      data: null,
      st_id: this.auth().id,
    };
  },

  computed: {
    barChart() {
      let users = this.users;
      this.score = [];
      this.answersCount.forEach((element) => {
        if (element.header == this.selected) {
          let text = element.count;
          let result = text;
          this.score.push({ x: element.answer, y: result });
        }
      });

      return {
        users,
        series: [
          {
            data: this.score,
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: this.score.length * 30,
          },
          yaxis: {
            labels: {
              maxWidth: 140,
              offsetX: -50,
            },
          },
          xaxis: {
            tickAmount: 4,
          },
          plotOptions: {
            bar: {
              borderRadius: 7,
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: true,
            textAnchor: "start",
            formatter: function (val, opt) {
              return val + " " + "-  " + ((val * 100) / users).toFixed(1) + "%";
            },
            offsetX: 20,
          },
        },
      };
    },

    AgePieChart() {
      return {
        series: this.ages_count,
        chartOptions: {
          chart: {
            width: 300,
            type: "pie",
          },
          labels: this.ages,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    },
    CountryPieChart() {
      return {
        series: this.countries_count,
        chartOptions: {
          chart: {
            width: 330,
            type: "pie",
          },
          labels: this.countries,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    },

    GenderPieChart() {
      return {
        series: this.genders,
        chartOptions: {
          chart: {
            width: 310,
            type: "pie",
          },
          labels: ["ذكر", "انثى"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      };
    },
  },
  mounted() {
    this.$store.dispatch("groups/score").then((res) => {
      this.answersCount = res;
      console.log(this.answersCount);
      for (let i = 0; i <= this.answersCount.length; i++) {
        if (this.answersCount[i].question_id == 13) {
          this.label3.push(this.answersCount[i].answer);
        }
      }
    });

    this.$store.dispatch("groups/questions").then((response) => {
      this.z = response;
      for (let i = 0; i < this.z.length; i++) {
        this.question_img.push({ question: this.z[i], img_num: i });
      }
    });

    this.$store.dispatch("groups/usersCount").then((response) => {
      this.users = response;
      this.users = response[0].userscount;
      this.males = response[0].male;
      this.females = response[0].female;
      this.genders.push(response[0].male);
      this.genders.push(response[0].female);

      console.log(this.genders);

      for (const key in response[0].ages) {
        this.ages_count.push(response[0].ages[key]);
        this.ages.push(key);
      }

      for (const key in response[0].countries) {
        this.countries_count.push(response[0].countries[key]);
        this.countries.push(key);
      }
    });
  },

  methods: {
    setQuestion(question) {
      console.log(this.img_index);
      (this.score = []), (this.selected = question);
      for (let i = 0; i < this.question_img.length; i++) {
        if (this.question_img[i].question == this.selected) {
          this.img_index = this.question_img[i].img_num;
        }
      }
    },

    filter() {
      this.answersCount = [];
      this.state = false;
      this.$store
        .dispatch("groups/score", this.selected_filters)
        .then((res) => {
          this.state = true;
          this.answersCount = res;
          console.log(this.answersCount);
        });
    },

    setAgeGroup(age_group) {
      if (age_group == 5) {
        delete this.selected_filters.age_group;
      } else {
        Object.assign(this.selected_filters, { age_group: age_group });
      }
    },
    setGender(gender) {
      if (gender == 2) {
        delete this.selected_filters.gender;
      } else {
        Object.assign(this.selected_filters, { gender: gender });
      }
      console.log(gender);
    },
    setCountry(country) {
      console.log(country);
      if (country == "الكل") {
        delete this.selected_filters.gender;
      } else {
        Object.assign(this.selected_filters, { country: country });
      }
      console.log(gender);
    },
    confirm(exam_type) {
      this.data = {
        exam_type: exam_type,
      };
      console.log(this.data);
      // this.$store.dispatch("groups/confirmScore", this.data).then(response => {
      //   //    console.log(response);
      //   alert("تم اعتماد النتيجة");
      // });
      this.$store.dispatch("user/pre_exam", this.st_id);
    },
    test_method() {
      for (const [value, key] of this.series) {
        Array.from(value, (el) => el.answer);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main_wrapper {
  width: 90%;
  padding: 0 3rem;
  margin-bottom: 1rem;
  .pre_btn {
    background-color: #1dc7ea;
    border-color: #1dc7ea;
  }
  option.sel {
    padding: 5px;
    border: 1px solid #e5dada !important;
    background: red !important;
    margin-top: 20px !important;
    // .el-select-dropdown__list
  }
}

.select_boxes_items {
  text-align: right;
  margin-right: 10px;
}
</style>
